<template>
    <auth-wrapper>
        <app-loader :value="$apollo.loading"/>
        <v-snackbar v-model="error" color="error">{{ errorMessage }}</v-snackbar>
        <v-card-title primary-title class="justify-center mb-5">Set your Rentify password</v-card-title>
        <v-card-text class="body-2 justify-center text-center">
            Set a password below to continue
        </v-card-text>

        <v-card-text class="mb-0 pb-0">
            <v-form v-model="valid" autocomplete="off">
                <text-field
                        label="Email"
                        name="login"
                        prepend-icon="mdi-email"
                        :rules="[rules.required]"
                        v-model="resetEmail"
                        readonly
                        disabled
                ></text-field>

                <text-field
                        label="Password"
                        :type="showPass ? 'text' : 'password'"
                        prepend-icon="mdi-lock"
                        :rules="rules"
                        v-model="password"
                ></text-field>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                rounded
                outlined
                color="primary"
                :disabled="!valid"
                :loading="loading"
                @click="setPassword"
            >Set password
            </v-btn>
        </v-card-actions>
    </auth-wrapper>

</template>

<script>
    import {resetEmail, setPassword} from '@/graphql'
    import { user } from '@/graphql'
    import Cookie from 'js-cookie'
    import {BACKEND_DOMAIN} from '@/variables.js';

    export default {
        props: ['secret'],
        apollo: {
            resetEmail: {
                query: resetEmail,
                variables () {
                    return {
                        token: this.$route.params.secret
                    }

                }
            },
            user
        },
        data: () => ({
            valid: null,
            showPass: false,
            password: null,
            error: false,
            errorMessage: null,
            loading: false,
            rules: [
                v => !!v || 'This field is required',
                v => !!v && v.length >= 6 || 'Password must be at least 6 characters long',
            ]
        }),
        methods: {
            handleReset({data}) {
                const {token} = data.setPassword;
                if (BACKEND_DOMAIN) {
                    Cookie.set('JWT', token, {domain: BACKEND_DOMAIN});
                } else {
                    Cookie.set('JWT', token);
                }
                window.location.reload()
            },
            setPassword () {
                this.loading = true;
                this.$apollo.mutate({
                    mutation: setPassword,
                    variables: {
                        token: this.$route.params.secret,
                        password: this.password
                    }
                }).catch((error) => {
                    this.error = true;
                    this.errorMessage = error.message.replace('GraphQL error: ', '');
                    this.loading = false
                }).then((result) => {
                  this.handleReset(result);
                  this.loading = false
                })

            }
        },
        watch: {
            user (value) {
                if (value) {
                    this.$router.push('/')
                }
            }
        }

    }
</script>
